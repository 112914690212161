import { Link } from "gatsby"
import { uniqueId } from "lodash"
import React, { useState } from "react"
import showdown from "showdown"
import * as styles from "./MediaSection.module.scss"
import { MediaSectionProps } from "./MediaSectionProps"
import { MediaSetLayout } from "./MediaSetLayout"
import { BodyCopy } from "../BodyCopy/BodyCopy"
import { BodyCopyAlignment } from "../BodyCopy/BodyCopyAlignment"
import { Container } from "../Container/Container"
import { Image } from "../Image/Image"
import { MediaSetCarousel } from "../MediaSetCarousel/MediaSetCarousel"
import { MediaSetGrid } from "../MediaSetGrid/MediaSetGrid"
import { Section } from "../Section/Section"

export function MediaSection(props: MediaSectionProps) {
    const eyebrow = props.eyebrow
    const bodyCopy = props.bodyCopy
    const action = props.action
    let eyebrowClassName: string
    let actionClassName: string

    switch (props.bodyCopyAlignment) {
        default:
        case BodyCopyAlignment.left:
            eyebrowClassName = styles.eyebrowLeft
            actionClassName = styles.actionLeft
            break
        case BodyCopyAlignment.center:
            eyebrowClassName = styles.eyebrowCenter
            actionClassName = styles.actionCenter
            break
    }
    return (
        <Section backgroundColor={props.backgroundColor} padding={true}>
            {(eyebrow || bodyCopy || action) && (
                <Container padding={true}>
                    {eyebrow && (
                        <div className={eyebrowClassName}>
                            <div className={styles.eyebrowText}>{eyebrow}</div>
                        </div>
                    )}
                    <BodyCopy markdown={bodyCopy} alignment={props.bodyCopyAlignment} />
                    {action?.to && action?.text && (
                        <div className={actionClassName}>
                            <Link className={styles.actionLink} to={action.to}>
                                <span className={styles.actionText}>{action.text}</span>
                            </Link>
                        </div>
                    )}
                </Container>
            )}
            {props.media && (
                <Container padding={true}>
                    {(() => {
                        switch (props.mediaLayout) {
                            case MediaSetLayout.carousel:
                                return <MediaSetCarousel media={props.media} />
                            default:
                            case MediaSetLayout.grid:
                                return <MediaSetGrid media={props.media} />
                        }
                    })()}
                </Container>
            )}
        </Section>
    )
}
