import _ from "lodash"
import React from "react"
import * as styles from "./Section.module.scss"
import { SectionProps } from "./SectionProps"
import { BackgroundColor } from "../../constants/BackgroundColor"

export function Section(props: SectionProps) {
    let classNames = new Array<string>()
    switch (props.backgroundColor) {
        default:
        case BackgroundColor.main:
            classNames.push(styles.main)
            break
        case BackgroundColor.alt:
            classNames.push(styles.alt)
            break
    }

    if (props.className) {
        classNames.push(props.className)
    }

    if (props.padding) {
        classNames.push(styles.padding)
    }

    return <section className={_.join(classNames, ` `)}>{props.children}</section>
}
