import React from "react"
import { Helmet } from "react-helmet"
import { SeoProps } from "./SeoProps"

export function SEO(props: SeoProps) {
  const lang = props.lang || `en`
  const title = props.title || ``
  const titleSuffix = props.titleSuffix || ``
  const description = props.description || ``
  const meta = props.meta || []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title}`}
      titleTemplate={`%s${titleSuffix}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: twitter,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}
