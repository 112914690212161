import { MutableRefObject, useEffect } from "react"

// Performs a callback when one of the eventTypes happens outside the ref element
export function useOutsideEvent(ref: MutableRefObject<any>, eventTypes: Array<string>, callback: Function) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
        eventTypes.forEach(x => document.addEventListener(x, handleClickOutside))
        return () => {
            eventTypes.forEach(x => document.removeEventListener(x, handleClickOutside))
        }
    }, [ref])
}
