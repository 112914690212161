import React from "react"
import { ImageData } from "./ImageData"
import { Props } from "../../models/Props"

export function Image(props: Props<ImageData>) {
  const model = props.data

  return (
    <>
      <img alt={model?.text} className={props.className} src={model?.image?.publicURL} />
    </>
  )
}
