import { Link } from "gatsby"
import { uniqueId } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import * as styles from "./Footer.module.scss"
import { FooterProps } from "./FooterProps"
import { BackgroundColor } from "../../constants/BackgroundColor"
import { useOutsideEvent } from "../../utils/useOutsideEvent"
import { Container } from "../Container/Container"
import { Image } from "../Image/Image"
import { Section } from "../Section/Section"

export function Footer(props: FooterProps) {
    const data = props.data
    const buttonMenuText = `Menu` // todo: Add to layout
    const buttonCloseText = `Close` // todo: Add to layout
    const className = props.backgroundColor === BackgroundColor.alt ? styles.mainAlt : styles.main

    const footerRef = useRef(null)

    const [isOpen, setIsOpen] = useState(false)
    const [footerSpacerHeight, setFooterSpacerHeight] = useState(0)

    let mainElement: HTMLElement

    useEffect(() => setFooterSpacerHeight(mainElement?.clientHeight || 0))

    useOutsideEvent(footerRef, ["mousedown", "touchstart"], () => setIsOpen(false))

    const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    const menu = data?.menu?.map(menuItem => {
        const submenu = menuItem?.submenu?.map(item => {
            const [submenuItemKey] = useState(() => uniqueId(`footer-submenuItem-`))
            return (
                <li key={submenuItemKey} className={styles.menuItem}>
                    <Link className={styles.menuLink} to={item?.to}>
                        {item?.text}
                    </Link>
                </li>
            )
        })

        const [menuItemKey] = useState(() => uniqueId(`footer-menuItem-`))
        return (
            <ul key={menuItemKey} className={styles.menu}>
                <li className={styles.menuItem}>
                    <Link className={styles.menuTitle} to={menuItem?.title?.to}>
                        {menuItem?.title?.text}
                    </Link>
                </li>
                {submenu}
            </ul>
        )
    })
    const social = data?.social?.map(socialItem => {
        const [socialItemKey] = useState(() => uniqueId(`footer-socialItem-`))
        return (
            <li key={socialItemKey} className={styles.socialItem}>
                <a className={styles.socialLink} href={socialItem?.link?.to} target="_blank">
                    <Image data={socialItem?.icon} className={styles.socialImage} />
                    <span className={styles.socialText}>{socialItem?.link?.text}</span>
                </a>
            </li>
        )
    })
    const languages = data?.languages?.map(language => {
        const [languageKey] = useState(() => uniqueId(`footer-language-`))
        return (
            <li key={languageKey} className={styles.languagesItem}>
                <Link className={styles.languagesLink} to={language?.to}>
                    {language?.text}
                </Link>
            </li>
        )
    })
    return (
        <footer ref={footerRef}>
            <div className={`${className} ${isOpen ? styles.mainOpen : ``}`} ref={x => (mainElement = x)}>
                <Container className={styles.container}>
                    <div className={styles.heading}>
                        <Link className={styles.headingLink} to={data?.heading?.to}>
                            {data?.heading?.text}
                        </Link>
                    </div>
                    <nav className={styles.nav}>
                        {menu}
                        <ul className={styles.social}>{social}</ul>
                        <ul className={styles.languages}>{languages}</ul>
                    </nav>
                </Container>
                <div className={styles.button}>
                    <button className={styles.buttonFrame} onClick={e => toggleMenu(e)}>
                        <div className={styles.buttonMenu}>
                            <img className={styles.buttonImage} src={`/images/bars-solid.svg`} />
                            <span>{buttonMenuText}</span>
                        </div>
                        <div className={styles.buttonClose}>
                            <img className={styles.buttonImage} src={`/images/times-solid.svg`} />
                            <span>{buttonCloseText}</span>
                        </div>
                    </button>
                </div>
            </div>
            {data?.copyright && (
                <Section backgroundColor={props?.backgroundColor}>
                    <Container className={styles.copyright}>
                        {`© ${data?.copyright} ${new Date().getFullYear()}`}
                    </Container>
                </Section>
            )}
            <div className={styles.footerSpacer} style={{ height: footerSpacerHeight }}></div>
        </footer>
    )
}
