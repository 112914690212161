import React from "react"
import * as styles from "./MediaCard.module.scss"
import { MediaCardProps } from "./MediaCardProps"
import { Image } from "../Image/Image"
import { YouTubeVideo } from "../YouTubeVideo/YouTubeVideo"

export function MediaCard(props: MediaCardProps) {
    const media = props?.media
    const youtubeId = props?.media?.youtube
    const hasYoutubeId = !!youtubeId
    const caption = media?.text
    return (
        <div className={styles.main}>
            <div className={styles.imageFrame}>
                {hasYoutubeId && (
                    <YouTubeVideo
                        id={youtubeId}
                        poster={media}
                        enableControls={props?.videoEnableControls}
                        resetOnPause={props?.videoResetOnPause}
                    />
                )}
                {!hasYoutubeId && <Image className={styles.image} data={media} />}
            </div>
            {caption && <div className={styles.caption}>{media?.text}</div>}
        </div>
    )
}
