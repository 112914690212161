export class FormFieldState {
    constructor(name: string, value: string, touched: boolean) {
        this.name = name;
        this.value = value;
        this.touched = touched;
    }
    name: string;
    value: string;
    touched: boolean;
    focused: boolean;

    reset = () => {
        this.value = ``;
        this.focused = false;
        this.touched = false;
    };
}
