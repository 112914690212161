import { uniqueId } from "lodash"
import React, { useState } from "react"
import * as styles from "./MediaSetGrid.module.scss"
import { MediaSetGridProps } from "./MediaSetGridProps"
import { MediaCard } from "../MediaCard/MediaCard"

export function MediaSetGrid(props: MediaSetGridProps) {
    const tiles = props?.media?.map((media, index) => {
        const [key] = useState(() => uniqueId(`mediasetGrid-card-`))
        return (
            <div key={key} className={styles.tile}>
                <MediaCard media={media} videoEnableControls={true} />
            </div>
        )
    })
    return <div className={styles.tiles}>{tiles}</div>
}
