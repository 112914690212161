import _ from "lodash"
import React from "react"
import * as styles from "./Page.module.scss"
import { PageTemplateProps } from "./PageTemplateProps"
import { BodyCopy } from "../../components/BodyCopy/BodyCopy"
import { Container } from "../../components/Container/Container"
import { Form } from "../../components/Form/Form"
import { Image } from "../../components/Image/Image"
import { MediaSectionStack } from "../../components/MediaSection/MediaSectionStack"
import { NewLayout } from "../../components/NewLayout/NewLayout"
import { Section } from "../../components/Section/Section"
import { SEO } from "../../components/SEO/SEO"
import { Spacer } from "../../components/Spacer/Spacer"
import { BackgroundColor } from "../../constants/BackgroundColor"

export class PageTemplate {
    constructor(props: PageTemplateProps) {
        const data = props?.data
        const page = data?.page?.nodes[0]
        const pageFrontmatter = page?.frontmatter

        // SEO
        const layout = _.find(data?.layout?.nodes, layoutNode => {
            return layoutNode?.frontmatter?.id === pageFrontmatter?.layout
        })

        // Hero
        const hero = pageFrontmatter?.hero

        // media sections
        const mediasets = data?.mediasets?.nodes?.map(x => x.frontmatter) || []
        const mediaSections = pageFrontmatter.mediasets
        const mediaSectionsCount = mediaSections?.length || 0

        // form
        const form = _.find(data?.form?.nodes, formNode => {
            return formNode?.frontmatter?.id === pageFrontmatter?.form
        })

        // body copy
        const body = page?.html
        const hasBody = !!body

        // backgrounds
        let backgroundCounter = 0
        const getBackgroundColor = () => (backgroundCounter % 2 ? BackgroundColor.main : BackgroundColor.alt)
        let formBackgroundColor: BackgroundColor
        let bodyBackgroundColor: BackgroundColor
        let footerBackgroundColor: BackgroundColor
        if (mediaSectionsCount) {
            backgroundCounter += mediaSectionsCount
        }
        if (form) {
            formBackgroundColor = getBackgroundColor()
            backgroundCounter++
        }
        if (body) {
            bodyBackgroundColor = getBackgroundColor()
            backgroundCounter++
        }
        footerBackgroundColor = getBackgroundColor()
        backgroundCounter++

        return (
            <NewLayout data={layout} footerBackgroundColor={footerBackgroundColor}>
                <SEO
                    title={pageFrontmatter?.title}
                    titleSuffix={layout?.frontmatter?.suffix}
                    description={pageFrontmatter?.description}
                />
                <Spacer />
                {hero && (
                    <Section>
                        <Container>
                            <Image data={hero} className={styles.heroImage} />
                            <Spacer />
                            <Spacer />
                        </Container>
                    </Section>
                )}
                {mediaSections && mediasets && (
                    <MediaSectionStack mediaSections={mediaSections} mediasets={mediasets} />
                )}
                {form && <Form data={form} backgroundColor={formBackgroundColor} />}
                {hasBody && (
                    <Section backgroundColor={bodyBackgroundColor} padding={true}>
                        <Container padding={true}>
                            <BodyCopy html={body} />
                        </Container>
                    </Section>
                )}
            </NewLayout>
        )
    }
}
