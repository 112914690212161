import showdown from "showdown"

export class MarkdownContentConverter {
    static toHtml = (markdown: string, imageFrameClassName?: string) => {
        //  Finds an image, moves its `title` attribute to a caption `span`, and wraps it in a CSS class name.
        //
        //  Example Input:
        //  <p>
        //      <img src="/image.jpg" alt="Alt Text" title="Title Text" />
        //  </p>
        //
        //  Exapmle Output:
        //  <p class="imageFrameClsasName">
        //      <img src="/image.jpg" alt="Alt Text" />
        //      <span>Title Text</span>
        //  </p>
        var imageFrameExtension = () => {
            return [
                {
                    type: "output",
                    regex: /\<p\>\s*\<img\s*src=\"(.*)\"\s*alt\=\"(.*)\"\s*title\=\"(.*)\"\s*\/\>\s*\<\/p\>/g,
                    replace: `<p class="${imageFrameClassName || ``}"><img src=\"$1\" alt=\"$2\" /><span>$3</span></p>`,
                },
            ]
        }

        const converter = new showdown.Converter({ extensions: [imageFrameExtension] })
        const html = converter.makeHtml(markdown)
        return html
    }
}
