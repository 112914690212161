import _, { uniqueId } from "lodash"
import React, { useState } from "react"
import { MediaSection } from "../../components/MediaSection/MediaSection"
import { MediaSectionStackProps } from "../../components/MediaSection/MediaSectionStackProps"
import { BackgroundColor } from "../../constants/BackgroundColor"
import { Spacer } from "../Spacer/Spacer"

export function MediaSectionStack(props: MediaSectionStackProps) {
    const mediasetDataList = props.mediasets
    const mediaSectionComponents =
        props?.mediaSections?.map((mediaSectionData, index) => {
            const [key] = useState(() => uniqueId(`mediaSection-`))
            const mediasetId = mediaSectionData.mediaset
            const mediasetData = _.find(mediasetDataList, x => x.id === mediasetId)
            const backgroundColor = index % 2 === 0 ? BackgroundColor.alt : BackgroundColor.main
            return (
                <MediaSection
                    key={key}
                    backgroundColor={backgroundColor}
                    bodyCopyAlignment={mediaSectionData.alignment}
                    eyebrow={mediaSectionData.eyebrow}
                    bodyCopy={mediaSectionData.copy}
                    action={mediaSectionData.action}
                    mediaLayout={mediaSectionData.layout}
                    media={mediasetData?.media}
                />
            )
        }) || []
    return <>{mediaSectionComponents}</>
}
