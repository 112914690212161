import _ from "lodash"
import React, { useState } from "react"
import YouTube from "react-youtube"
import * as styles from "./YouTubeVideo.module.scss"
import { YouTubeVideoProps } from "./YouTubeVideoProps"
import { Image } from "../Image/Image"

export function YouTubeVideo(props: YouTubeVideoProps) {
    const [hidePoster, setHidePoster] = useState(false)
    const [posterRef, setPosterRef] = useState<HTMLElement>()
    const play = (flag: boolean) => {
        if (player) {
            if (!flag) {
                if (props.resetOnPause) {
                    posterRef.style.display = "block"
                    setHidePoster(false)
                }
            } else {
                setHidePoster(true)
                if (props.resetOnPause) {
                    player.seekTo(0)
                }
                player.playVideo()

                // hide poster
                setTimeout(() => {
                    posterRef.style.display = "none"
                }, 200) // the timeout ms must match the transition set in the CSS
            }
        }
    }

    const [player, setPlayer] = useState<any>()

    return (
        <div className={`${styles.main} ${hidePoster ? styles.mainPlaying : ``}`}>
            <YouTube
                opts={{
                    playerVars: {
                        controls: props.enableControls ? 1 : 0,
                        modestbranding: 1,
                    },
                }}
                className={styles.video}
                videoId={props?.id}
                onReady={x => setPlayer(x.target)}
                onPause={() => play(false)}
                onEnd={() => play(false)}
            />
            <a className={styles.poster} onClick={() => play(true)} ref={x => setPosterRef(x)}>
                <Image className={styles.posterImage} data={props.poster} />
                <div className={styles.posterButton} />
            </a>
        </div>
    )
}
