import _ from "lodash"
import React from "react"
import * as styles from "./BodyCopy.module.scss"
import { BodyCopyAlignment } from "./BodyCopyAlignment"
import { BodyCopyProps } from "./BodyCopyProps"
import { MarkdownContentConverter } from "../MarkdownContent/MarkdownContentConverter"

export function BodyCopy(props: BodyCopyProps) {
    let classNames = new Array<string>()
    classNames.push(styles.main)

    if (props.className) {
        classNames.push(props.className)
    }

    switch (props.alignment) {
        case BodyCopyAlignment.center:
            classNames.push(styles.mainCenter)
            break
        case BodyCopyAlignment.left:
            classNames.push(styles.mainLeft)
            break
    }

    const html = props.html || MarkdownContentConverter.toHtml(props.markdown, styles.markdownImageFrame)

    return <>{html && <div className={_.join(classNames, ` `)} dangerouslySetInnerHTML={{ __html: html }} />}</>
}
