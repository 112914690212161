import { uniqueId } from "lodash"
import React, { useState } from "react"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import "swiper/components/navigation/navigation.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import * as styles from "./MediaSetCarousel.module.scss"
import { MediaSetCarouselProps } from "./MediaSetCarouselProps"
import { Breakpoints } from "../../constants/Breakpoints"
import { MediaCard } from "../MediaCard/MediaCard"

export function MediaSetCarousel(props: MediaSetCarouselProps) {
    SwiperCore.use([Autoplay, Navigation])
    const [swiperButtonPrevId] = useState(() => uniqueId(`mediasetCarousel-swiperButtonPrev-`))
    const [swiperButtonNextId] = useState(() => uniqueId(`mediasetCarousel-swiperButtonNext-`))

    const slides =
        props.media?.map(media => {
            const [slideKey] = useState(() => uniqueId(`mediasetCarousel-slide-`))
            return (
                <SwiperSlide key={slideKey}>
                    <MediaCard media={media} videoResetOnPause={true} />
                </SwiperSlide>
            )
        }) || []

    let breakpoints: any = {}
    breakpoints[Breakpoints.phone] = { slidesPerView: 1 }
    breakpoints[Breakpoints.tablet] = { slidesPerView: 3 }
    breakpoints[Breakpoints.laptop] = { slidesPerView: 4 }
    breakpoints[Breakpoints.desktop] = { slidesPerView: 4 }

    let navigation = {
        prevEl: `#${swiperButtonPrevId}`,
        nextEl: `#${swiperButtonNextId}`,
        disabledClass: styles.swiperButtonDisabled,
        hiddenClass: styles.swiperButtonHidden,
    }

    return (
        <div className={`${styles.content} slide-count-${slides.length}`}>
            <Swiper autoplay={true} breakpoints={breakpoints} navigation={navigation} spaceBetween={1}>
                {slides}
            </Swiper>
            <a id={swiperButtonPrevId} className={styles.swiperButtonPrev}>
                <img src={`/images/caret-left-solid.svg`} />
            </a>
            <a id={swiperButtonNextId} className={styles.swiperButtonNext}>
                <img src={`/images/caret-right-solid.svg`} />
            </a>
        </div>
    )
}
