import { Link } from "gatsby"
import { uniqueId } from "lodash"
import React, { useEffect, useState } from "react"
import * as styles from "./Header.module.scss"
import { HeaderData } from "./HeaderData"
import { Props } from "../../models/Props"
import { Container } from "../Container/Container"
import { Section } from "../Section/Section"

export function Header(props: Props<HeaderData>) {
    const data = props.data
    const subheading = data?.subheading
    const [fixedHeaderTrigger, setElement] = useState<HTMLElement>()
    const [isVisible, setVisibility] = useState(false)

    useEffect(() => {
        const listener = () => {
            if (fixedHeaderTrigger) {
                let elementY: number = fixedHeaderTrigger.getBoundingClientRect().top
                elementY = fixedHeaderTrigger.getBoundingClientRect().top
                setVisibility(elementY <= 0)
            }
        }
        document.addEventListener(`scroll`, listener, { passive: true })

        return () => document.removeEventListener(`scroll`, listener)
    }, [fixedHeaderTrigger, isVisible])

    const menuLinks =
        data?.menu?.map(item => {
            const [menuLinkKey] = useState(() => uniqueId(`header-menuLink-`))
            return (
                <Link key={menuLinkKey} className={styles.menuLink} to={item.to}>
                    {item.text}
                </Link>
            )
        }) || []

    return (
        <>
            <header className={styles.main}>
                <Section padding={true}>
                    <Container>
                        <div className={styles.heading}>
                            <Link className={styles.headingLink} to={data?.heading?.to}>
                                {data?.heading?.text}
                            </Link>
                        </div>
                        {!!subheading && <div className={styles.subheading}>{data?.subheading}</div>}
                        <nav className={styles.menu}>{menuLinks}</nav>
                    </Container>
                </Section>
            </header>
            <div ref={x => setElement(x)}></div>
            <div className={styles.fixed} style={isVisible ? { top: `0` } : { top: `-100%` }}>
                <div className={styles.fixedContainer}>
                    <Link className={styles.fixedHeadingLink} to={data?.heading?.to}>
                        {data?.heading?.text}
                    </Link>
                    <nav className={styles.fixedMenu}>{menuLinks}</nav>
                </div>
            </div>
        </>
    )
}
