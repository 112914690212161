import React from "react"
import { NewLayoutProps } from "./NewLayoutProps"
import { Footer } from "../Footer/Footer"
import { Header } from "../Header/Header"
import { Spacer } from "../Spacer/Spacer"

export function NewLayout(props: NewLayoutProps) {
    const frontmatter = props.data?.frontmatter

    return (
        <>
            <Spacer />
            <Header data={frontmatter?.header} />
            <main>{props.children}</main>
            <Footer data={frontmatter?.footer} backgroundColor={props.footerBackgroundColor} />
        </>
    )
}
