import { navigate } from "gatsby-link"
import _, { uniqueId } from "lodash"
import React, { useState } from "react"
import showdown from "showdown"
import * as styles from "./Form.module.scss"
import { FormFieldState } from "./FormFieldState"
import { FormProps } from "./FormProps"
import { BodyCopy } from "../BodyCopy/BodyCopy"
import { Container } from "../Container/Container"
import { Section } from "../Section/Section"
import { Spacer } from "../Spacer/Spacer"

export function Form(props: FormProps) {
    const frontmatter = props.data?.frontmatter
    const [formState, setFormState] = useState<Array<FormFieldState>>([])
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    let initialFormState = new Array<FormFieldState>()
    const bodyCopy = new showdown.Converter().makeHtml(props?.data?.frontmatter?.copy)

    const focusField = (name: string, focus: boolean) => {
        if (!isInitialized) return
        let newFormState = [...formState]
        let newFieldState = _.find(newFormState, x => x.name === name)
        newFieldState.focused = focus
        setFormState(newFormState)
    }

    const updateField = (name: string, value: string) => {
        if (!isInitialized) return
        let newFormState = [...formState]
        let newFieldState = _.find(newFormState, x => x.name === name)
        newFieldState.value = value
        newFieldState.touched = true
        setFormState(newFormState)
    }

    const updateFieldCheckbox = (name: string, value: string) => {
        let valueBool = /true/i.test(value)
        valueBool = !valueBool
        updateField(name, `${valueBool}`)
    }

    const updateFieldCheckboxList = (name: string, value: string) => {
        let field = _.find(formState, x => x.name === name)
        let valueItems = _.filter(field.value.split(","), x => !!x)
        if (_.find(valueItems, x => x === value)) {
            _.remove(valueItems, x => x === value)
        } else {
            valueItems.push(value)
        }
        const newValue = _.join(valueItems, ",")
        updateField(name, newValue)
    }

    const resetForm = (e: any) => {
        let newFormState = [...formState]
        _.each(newFormState, fieldState => {
            fieldState.reset()
        })
        setFormState(newFormState)
    }

    const touchForm = (e: any) => {
        let newFormState = [...formState]
        _.each(newFormState, fieldState => {
            fieldState.touched = true
        })
        setFormState(newFormState)
    }

    const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        let submitFormState = {}
        _.forEach(
            _.filter(formState, field => !!field?.value),
            field => {
                submitFormState[field.name] = field.value
            }
        )

        const formBodyData = {
            "form-name": frontmatter.form,
            ...submitFormState,
        }
        const formBody = Object.keys(formBodyData)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formBodyData[key]))
            .join("&")

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: formBody,
        })
            .then(() => navigate(frontmatter.confirmation))
            .catch(error => alert(error))
    }

    const fields = frontmatter?.fields?.map(field => {
        const required = field?.required
        const [formFieldKey] = useState(() => uniqueId(`form-field-`))

        let fieldState: FormFieldState
        if (!isInitialized) {
            fieldState = new FormFieldState(field.name, ``, false)
            initialFormState.push(fieldState)
        } else {
            fieldState = _.find(formState, x => x.name === field.name)
        }

        switch (field?.type) {
            case "checkbox":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldCheckbox} 
                            ${required && styles.fieldRequired} 
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                    >
                        <label className={styles.fieldLabel}>
                            <input
                                className={styles.fieldInput}
                                name={field?.name}
                                onChange={x => updateFieldCheckbox(field.name, x.target.value)}
                                required={field?.required}
                                title={field.hint}
                                type="checkbox"
                                value={fieldState.value}
                            />
                            <div className={styles.fieldLabelText}>{field?.label}</div>
                            {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                        </label>
                    </li>
                )

            case "checkboxes":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field}
                            ${styles.fieldCheckboxes}
                            ${required && styles.fieldRequired}
                            ${fieldState.touched && styles.fieldTouched}
                            ${fieldState.value && styles.fieldWithValue}
                        `}
                    >
                        <input type="hidden" name={field.name} value={fieldState.value} />
                        <label className={styles.fieldLabel}>
                            <div className={styles.fieldLabelText}>{field?.label}</div>
                            {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                        </label>
                        <ul className={styles.fieldOptionList}>
                            {field?.options.map(option => {
                                const [formFieldOptionKey] = useState(() => uniqueId(`form-field-checkboxes-item-`))
                                return (
                                    <li key={formFieldOptionKey} className={styles.fieldOption}>
                                        <label className={styles.fieldOptionLabel}>
                                            <input
                                                type="checkbox"
                                                value={option.text}
                                                className={styles.fieldInput}
                                                onChange={x => updateFieldCheckboxList(field.name, option.text)}
                                            />
                                            {option.text}
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                )

            case "date":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldDate} 
                            ${required && styles.fieldRequired} 
                            ${fieldState.focused && styles.fieldFocused}
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <input
                            className={styles.fieldInput}
                            name={field?.name}
                            onBlur={x => focusField(field.name, false)}
                            onChange={x => updateField(field.name, x.target.value)}
                            onFocus={x => focusField(field.name, true)}
                            required={field?.required}
                            title={field.hint}
                            type="date"
                            value={fieldState.value}
                        />
                        {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                    </li>
                )

            case "email":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldEmail}
                            ${required && styles.fieldRequired} 
                            ${fieldState.focused && styles.fieldFocused}
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                            `}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <input
                            className={styles.fieldInput}
                            name={field?.name}
                            onBlur={x => focusField(field.name, false)}
                            onChange={x => updateField(field.name, x.target.value)}
                            onFocus={x => focusField(field.name, true)}
                            placeholder={field.label}
                            required={field?.required}
                            title={field.hint}
                            type="email"
                            value={fieldState.value}
                        />
                        {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                    </li>
                )

            case "heading":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field}
                            ${styles.fieldHeading}
                        `}
                    >
                        <h2 className={styles.fieldLabel}>{field?.label}</h2>
                        {field?.hint && <p className={styles.fieldHint}>{field?.hint}</p>}
                    </li>
                )

            case "select":
                if (!fieldState.value) {
                    fieldState.value = _.first(field?.options || [])?.text
                }
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldSelect} 
                            ${required && styles.fieldRequired} 
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <select
                            className={styles.fieldInput}
                            name={field?.name}
                            onChange={x => updateField(field.name, x.target.value)}
                            required={field?.required}
                            title={field.hint}
                            value={fieldState.value || ``}
                        >
                            {field?.options.map(option => {
                                const [formFieldOptionKey] = useState(() => uniqueId(`form-field-option-`))
                                return (
                                    <option key={formFieldOptionKey} className={styles.fieldOption}>
                                        {option.text}
                                    </option>
                                )
                            })}
                        </select>
                        {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                    </li>
                )

            case "tel":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldTel}
                            ${required && styles.fieldRequired} 
                            ${fieldState.focused && styles.fieldFocused}
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <input
                            className={styles.fieldInput}
                            name={field?.name}
                            onBlur={x => focusField(field.name, false)}
                            onChange={x => updateField(field.name, x.target.value)}
                            onFocus={x => focusField(field.name, true)}
                            placeholder={field.label}
                            required={field?.required}
                            title={field.hint}
                            type="tel"
                            value={fieldState.value}
                        />
                        {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                    </li>
                )

            case "text":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldText} 
                            ${required && styles.fieldRequired} 
                            ${fieldState.focused && styles.fieldFocused}
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <input
                            className={styles.fieldInput}
                            type="text"
                            name={field?.name}
                            onBlur={x => focusField(field.name, false)}
                            onChange={x => updateField(field.name, x.target.value)}
                            onFocus={x => focusField(field.name, true)}
                            placeholder={field.label}
                            required={field?.required}
                            title={field.hint}
                            value={fieldState.value}
                        />
                        {field?.hint && <div className={styles.fieldHint}>{field?.hint}</div>}
                    </li>
                )

            case "textarea":
                return (
                    <li
                        key={formFieldKey}
                        className={`
                            ${styles.field} 
                            ${styles.fieldTextarea} 
                            ${required && styles.fieldRequired} 
                            ${fieldState.touched && styles.fieldTouched} 
                            ${fieldState.value && styles.fieldWithValue} 
                        `}
                        title={field.hint}
                    >
                        <label className={styles.fieldLabel}>{field?.label}</label>
                        <textarea
                            className={styles.fieldInput}
                            name={field?.name}
                            onBlur={x => focusField(field.name, false)}
                            onChange={x => updateField(field.name, x.target.value)}
                            onFocus={x => focusField(field.name, true)}
                            placeholder={field.hint}
                            required={field?.required}
                            value={fieldState.value}
                            title={field.hint}
                        ></textarea>
                    </li>
                )
        }
        return <li key={formFieldKey} className={styles.field}></li>
    })

    if (!isInitialized) {
        setFormState(initialFormState)
        setIsInitialized(true)
    }

    return (
        <Section backgroundColor={props.backgroundColor} className={props.className} padding={true}>
            <Container padding={true}>
                <BodyCopy html={bodyCopy} />
                <form
                    action={frontmatter?.confirmation}
                    data-netlify="true"
                    className={styles.fields}
                    name={frontmatter?.form}
                    method="POST"
                    onSubmit={x => submitForm(x)}
                >
                    <input type="hidden" name="form-name" value={frontmatter.form} />
                    <ul className={styles.fieldList}>{fields}</ul>
                    <div className={styles.buttons}>
                        <button className={styles.buttonPrimary} type="submit" onClick={x => touchForm(x)}>
                            {frontmatter?.submit}
                        </button>
                        <button className={styles.buttonSecondary} type="reset" onClick={x => resetForm(x)}>
                            {frontmatter?.reset}
                        </button>
                    </div>
                </form>
            </Container>
        </Section>
    )
}
