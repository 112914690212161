import _ from "lodash"
import React from "react"
import * as styles from "./Container.module.scss"
import { ContainerProps } from "./ContainerProps"

export function Container(props: ContainerProps) {
    let classNames = new Array<string>()
    classNames.push(styles.container)

    if (props.className) {
        classNames.push(props.className)
    }

    if (props.padding) {
        classNames.push(styles.padding)
    }

    return <div className={_.join(classNames, ` `)}>{props.children}</div>
}
